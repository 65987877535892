// docReady utility function
function docReady(fn) {//IE9+
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

docReady(function () {//Above IE8
	if(document.querySelector('.roi-calculator')){
		roiBlock = document.querySelector('.roi-calculator');

		//// Progress bar
		let progressNode1 = document.querySelector("#progress-node--1");
		let progressNode2 = document.querySelector("#progress-node--2");
		let progressNode3 = document.querySelector("#progress-node--3");

		//// Input elements
		let monthlyOrdersInputElement = document.querySelector("#js-roi-orders");
		let averageOrderValueInputElement = document.querySelector("#js-roi-aov");
		let annualPurchaseFrequencyInputElement = document.querySelector("#js-roi-annual");
		let lifetimePurchasesInputElement = document.querySelector("#js-roi-lifetime");
		let proceedButton = document.querySelector("#js-roi-proceed");
		let proceedMessageElement = document.querySelector(".roi-calculator__proceed-message");
		let unlockCTAMessageElement = document.querySelector(".roi-calculator__unlock-cta-text");

		//// Panels
		let inputsPanel = document.querySelector(".roi-calculator__inputs");
		let outputsPanel = document.querySelector(".roi-calculator__outputs");
		let formPanel = document.querySelector(".roi-calculator__form");
		let metricsPanel = document.querySelector(".roi-calculator__metrics");
		let blurElements = document.getElementsByClassName('blur');


		//// Function to parse URL query parameters
		function getUrlParameter(name) {
			name = name.replace(/[\[\]]/g, "\\$&");
			var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
			var results = regex.exec(window.location.href);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		}

		// Check if there are query parameters for the inputs and set their values
		if (getUrlParameter("monthly")) {
			monthlyOrdersInputElement.value = parseFloat(getUrlParameter("monthly").replace(/,/g, ''));
			averageOrderValueInputElement.value = parseFloat(getUrlParameter("average").replace(/,/g, ''));
			annualPurchaseFrequencyInputElement.value = parseFloat(getUrlParameter("annual"));
			lifetimePurchasesInputElement.value = parseFloat(getUrlParameter("lifetime"));

			// Update the button
			proceedButton.classList.add("roi-calculator__proceed--unlock");
			proceedButton.textContent = "Unlock advanced metrics";
			unlockCTAMessageElement.classList.remove("hidden");

			// Update the progress bar for the first step
			progressNode1.classList.add("completed");
			progressNode2.classList.add("active");

			calculateROI();

			// Listen for new input changes
			document.querySelectorAll(".roi-calculator__input").forEach(function(element) {
				element.addEventListener("input", function() {
					calculateROI();
				});
			});

			// un-blur everything if there's a url param with the relevant value
			if (getUrlParameter("insights") && getUrlParameter("insights") == 'on') {

				// Update progress bar
				progressNode2.classList.add("completed");
				progressNode3.classList.add("active");

				// Hide calc submit button
				proceedButton.classList.add("roi-calculator__proceed--hidden");
				unlockCTAMessageElement.classList.add("hidden");
				
				// Replace form panel html
				formPanel.innerHTML = `
					<div class="roi-calculator__form-submitted-message">
						<p>Ready to start working towards these metrics?</p>
						<a href="https://loyaltylion.com/demo" class="block-button primary has-purple-background-color" target="_self" rel="noopener">Talk to sales</a>
					</div>
				`;

				// Reveal form + metrics
				inputsPanel.classList.add("roi-calculator__inputs--fixed-height");
				outputsPanel.classList.add("roi-calculator__outputs--fixed-height");
				formPanel.classList.add("active");
				metricsPanel.classList.add("active");
				for (var i = 0; i < blurElements.length; i++) {
					blurElements[i].classList.remove('blur--active');
				}
			}
		}

		/**
		 * Handle the calcs
		 */
		function calculateROI() {

			let	softwareFeeClassic = 399;
			let	softwareFeeAdvanced = 729;
			let	softwareFeePlus1 = 1650;
			let	softwareFeePlus2 = 6100;
			let	softwareFeePlus3 = 8300;
			let	softwareFeePlus4 = 12700;

			//// Input values
			let monthlyOrders = parseFloat(monthlyOrdersInputElement.value.replace(/,/g, ''));
			let averageOrderValue = parseFloat(averageOrderValueInputElement.value.replace(/,/g, ''));
			let annualPurchaseFrequency = parseFloat(annualPurchaseFrequencyInputElement.value);
			let lifetimePurchases = parseFloat(lifetimePurchasesInputElement.value);

			
			//// Output elements
			let totalRevenueOutput = document.querySelector("#js-roi-revenue");
			let returnOnInvestmentOutput = document.querySelector("#js-roi-roi");
			let newRedeemersOutput = document.querySelector("#js-roi-new-redeemers");
			let newRepeatPurchasesOutput = document.querySelector("#js-roi-new-repeats");
			let multiplierSymbol = document.querySelector(".multiplier");

			// Restricted metrics graphs
			let donut1 = document.querySelector("#donut-1");
			let donut2 = document.querySelector("#donut-2");
			let donut3 = document.querySelector("#donut-3");
			let donut4 = document.querySelector("#donut-4");

			//// Hardcoded figures
			let percentageDiscountReferredCustomers = 15 / 100;
			let percentageDiscountLoyaltyMembers = 5 / 100;
			let percentageOfMembersBecomingRedeemers = 0.2324;
			let percentageOfShoppersBecomingMembers = 0.3446;
			let revenueInnovativeLoyaltyReferralFeatures = 0;// no value given in spreadsheet
			let costInnovativeLoyaltyReferralFeatures = 0;// no value given in spreadsheet
			let successfullyReferredCustomersAnnually = 250;
			let percentageAnnualSpendRedeemerVersusShopper = 1.6445;
			let percentageAnnualSpendMemberVersusShopper = 0.3997;
			let percentageOrdersByMembersInvolvingReward = 0.33232649026217487;
			let percentagePurchaseFreqOfMemberAboveShopper = 0.2806;


			let costSoftwareFee = softwareFeeClassic * 12;
			if (monthlyOrders > 199999) {
				costSoftwareFee = softwareFeePlus4 * 12;
			} else if (monthlyOrders > 99999) {
				costSoftwareFee = softwareFeePlus3 * 12;
			} else if (monthlyOrders > 49999) {
				costSoftwareFee = softwareFeePlus2 * 12;
			} else if (monthlyOrders > 9999) {
				costSoftwareFee = softwareFeePlus1 * 12;
			} else if (monthlyOrders > 3999) {
				costSoftwareFee = softwareFeeAdvanced * 12;
			}

			//// Calculations
			var annualSpendAllShoppers = averageOrderValue * annualPurchaseFrequency;
			var annualSpendMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendMemberVersusShopper);
			var annualSpendRedeemingMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendRedeemerVersusShopper);
			var shoppers = (monthlyOrders / annualPurchaseFrequency ) * 12;
			var members = shoppers * percentageOfShoppersBecomingMembers;
			var newRedeemingMembersPerYear = members * percentageOfMembersBecomingRedeemers;
			var revenueCoreLoyalty = (annualSpendRedeemingMember - annualSpendMember) * newRedeemingMembersPerYear;
			var revenueCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * lifetimePurchases;
			var costCoreLoyalty = newRedeemingMembersPerYear * annualSpendRedeemingMember * percentageOrdersByMembersInvolvingReward * percentageDiscountLoyaltyMembers;
			var costCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * percentageDiscountReferredCustomers;
			
			var totalRevenueGenerated = revenueInnovativeLoyaltyReferralFeatures + revenueCoreLoyalty + revenueCoreReferrals;
			var newRepeatPurchasesPerYear = totalRevenueGenerated / averageOrderValue;
			var totalCosts = costInnovativeLoyaltyReferralFeatures + costCoreLoyalty + costCoreReferrals + costSoftwareFee;
			var returnOnInvestment = totalRevenueGenerated / totalCosts;

			var memberPurchaseFrequency = annualPurchaseFrequency * ( 1 + percentagePurchaseFreqOfMemberAboveShopper);	
			var redeemingMemberFrequency = annualPurchaseFrequency * ( 1 + percentageOrdersByMembersInvolvingReward);				
			
			/**
			 * Update the outputs
			*/
			totalRevenueOutput.textContent = Math.round(totalRevenueGenerated);
			returnOnInvestmentOutput.textContent = returnOnInvestment.toFixed(2);
			newRedeemersOutput.textContent = Math.round(newRedeemingMembersPerYear);
			newRepeatPurchasesOutput.textContent = Math.round(newRepeatPurchasesPerYear);
			multiplierSymbol.classList.remove("hidden");

			/**
			 * Update restricted metrics graphs
			*/
			function updateDonut(donutElement, prefix, percentage) {
				// Set the new percentage for the donut fill
				donutElement.style.setProperty('--p', percentage);
				// Add the new percentage to the donut inner text
				const donutFigureElement = donutElement.querySelector('.donut__figure');
				if (donutFigureElement) {
					donutFigureElement.textContent = prefix + percentage + '%';
				}
			}

			updateDonut(donut1, '', 23);
			updateDonut(donut2, '', 5.9);
			updateDonut(donut3, '+', redeemingMemberFrequency.toFixed(1));
			updateDonut(donut4, '+', 40);

			// Run comma formatting 
			document.querySelectorAll(".comma-format").forEach(function(element) {
				applyCommaFormatting(element);
			});
		}

		// Function to check if all inputs are valid
		function validateInputsAndToggleButton() {
			const monthlyOrders = parseFloat(monthlyOrdersInputElement.value.trim());
			const averageOrderValue = parseFloat(averageOrderValueInputElement.value.trim());
			const annualPurchaseFrequency = parseFloat(annualPurchaseFrequencyInputElement.value.trim());
			const lifetimePurchases = parseFloat(lifetimePurchasesInputElement.value.trim());

			const allInputsValid = 
				monthlyOrders > 0 &&
				averageOrderValue > 0 &&
				annualPurchaseFrequency > 0 &&
				lifetimePurchases > 0;

			if (allInputsValid) {
				// Add the active class to the button if all inputs are valid
				proceedButton.classList.add("active");
			} else {
				// Remove the active class if any input is invalid
				proceedButton.classList.remove("active");
			}
		}

		// Attach the input event listener to all inputs
		document.querySelectorAll(".roi-calculator__input").forEach(input => {
			input.addEventListener("input", validateInputsAndToggleButton);
		});

		/**
		 * Proceed button clicks
		 */
		proceedButton.addEventListener("click", function () {
			// Validate inputs
			const monthlyOrders = parseFloat(monthlyOrdersInputElement.value.trim());
			const averageOrderValue = parseFloat(averageOrderValueInputElement.value.trim());
			const annualPurchaseFrequency = parseFloat(annualPurchaseFrequencyInputElement.value.trim());
			const lifetimePurchases = parseFloat(lifetimePurchasesInputElement.value.trim());

			if (
				isNaN(monthlyOrders) || monthlyOrders <= 0 ||
				isNaN(averageOrderValue) || averageOrderValue <= 0 ||
				isNaN(annualPurchaseFrequency) || annualPurchaseFrequency <= 0 ||
				isNaN(lifetimePurchases) || lifetimePurchases <= 0
			) {
				// Show the message and disable button if any input is missing or <= 0
				proceedMessageElement.classList.remove("hidden");
				proceedButton.classList.remove("roi-calculator__proceed--unlock");
				proceedButton.classList.remove("active");
				proceedButton.textContent = "Calculate";
			} else {
				// Hide the message if all inputs are > 0
				proceedMessageElement.classList.add("hidden");

				if (proceedButton.classList.contains("roi-calculator__proceed--unlock")) {
					// When the button already has the "unlock" class and inputs are valid
					proceedButton.classList.add("roi-calculator__proceed--hidden");
					unlockCTAMessageElement.classList.add("hidden");
					progressNode2.classList.add("completed");
					progressNode3.classList.add("active");
					
					// Reveal form + blurred metrics
					inputsPanel.classList.add("roi-calculator__inputs--fixed-height");
					outputsPanel.classList.add("roi-calculator__outputs--fixed-height");
					formPanel.classList.add("active");
					metricsPanel.classList.add("active");

				} else {
					// Run the calculation for the first time
					calculateROI();

					document.querySelectorAll(".roi-calculator__input").forEach(function(element) {
						element.addEventListener("input", function() {
							calculateROI();
						});
					});

					// Update the button
					proceedButton.classList.add("roi-calculator__proceed--unlock");
					proceedButton.textContent = "Unlock advanced metrics";
					unlockCTAMessageElement.classList.remove("hidden");

					// Update the progress bar for the first step
					progressNode1.classList.add("completed");
					progressNode2.classList.add("active");
				}
			}
		});



		/**
		 * Copy your results
		 */
		let calcShareButton = document.querySelector("#calculatorCopy");

		calcShareButton.addEventListener("click", function () {
			// Get the current page permalink
			const permalink = calcShareButton.getAttribute("data-url");

			// Detect state of gated 'detailed metrics' insights
			const insightsState = calcShareButton.getAttribute("data-insights");

			// Create URL parameters for each of the four inputs
			let monthlyOrders = parseFloat(document.querySelector("#js-roi-orders").value.replace(/,/g, ''));
			let averageOrderValue = parseFloat(document.querySelector("#js-roi-aov").value.replace(/,/g, ''));
			let annualPurchaseFrequency = parseFloat(document.querySelector("#js-roi-annual").value);
			let lifetimePurchases = parseFloat(document.querySelector("#js-roi-lifetime").value);

			const utmParamString = '&utm_source=roi_calculator_link&utm_medium=roi_calculator_copy_link';
			const urlParams = `?monthly=${monthlyOrders}&average=${averageOrderValue}&annual=${annualPurchaseFrequency}&lifetime=${lifetimePurchases}&insights=${insightsState}${utmParamString}`;

			// Combine the data-url and URL parameters to create the final URL
			const finalUrl = permalink + urlParams;

			// Copy the final URL to clipboard
			const textField = document.createElement('textarea');
			textField.innerText = finalUrl;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand('copy');
			document.body.removeChild(textField);

			// Temporarily replace button inner HTML
			const originalHTML = calcShareButton.innerHTML;
			calcShareButton.innerHTML = 'Link copied to clipboard!';
			
			// Revert back to the original content after 3 seconds
			setTimeout(function () {
				calcShareButton.innerHTML = originalHTML;
			}, 3000);
		});



		/**
		 * 'Advanced Metrics' Hubspot form
		 */
		function hubspotForm() {
			
			var form = roiBlock.querySelector('.hs-form');
			
			if (form) {
				console.log('Form detected');

				// Add input field placeholders on the ROI Calculator hubspot form
				const textInput = form.querySelector('input[type="text"]');
				textInput.setAttribute('placeholder', 'First name');

				const emailInput = form.querySelector('input[type="email"]');
				emailInput.setAttribute('placeholder', 'Email');

				// Change the submit button text
				const hbsptSubmitButton = form.querySelector('input[type="submit"]');
				if (hbsptSubmitButton) {
					hbsptSubmitButton.setAttribute('value', 'Unlock advanced metrics');
				}

				// Setup a handler to run when the form passes validation
				form.addEventListener('hsvalidatedsubmit', function () {
					console.log('Form submitted');

					// Un-blur everything
					Array.from(blurElements).forEach(element => {
						element.classList.remove('blur--active');
					});
					
					// Update data attribute for insights state on 'copy link' button
					calcShareButton.setAttribute('data-insights', 'on');
					
					// Replace form panel html
					formPanel.innerHTML = `
						<div class="roi-calculator__form-submitted-message">
							<p>Ready to start working towards these metrics?</p>
							<a href="https://loyaltylion.com/demo" class="block-button primary has-purple-background-color" target="_self" rel="noopener">Talk to sales</a>
						</div>
					`;

				});

			} else {
				setTimeout(hubspotForm, 1000); // Poll for the form every second
			}
		}
		hubspotForm();


		//// Position the '$' prefix within a right-aligned input
		const prefixInput = document.getElementById('js-roi-aov');
		const prefixSymbol = prefixInput?.previousElementSibling;

		// Ensure both the input and prefix elements exist
		if (prefixInput && prefixSymbol) {

			const adjustPrefixPosition = () => {
				const inputValue = prefixInput.value;
				const testSpan = document.createElement('span');
				testSpan.style.position = 'absolute';
				testSpan.style.visibility = 'hidden';
				testSpan.style.whiteSpace = 'nowrap';
				testSpan.style.font = window.getComputedStyle(prefixInput).font;
				testSpan.textContent = inputValue || '0';
				document.body.appendChild(testSpan);
				
				const textWidth = testSpan.offsetWidth;
				document.body.removeChild(testSpan);
				
				const inputWidth = prefixInput.offsetWidth;
				const rightAlignPosition = inputWidth - textWidth - 22; // Adjust for padding
				
				prefixSymbol.style.left = `${rightAlignPosition}px`;
			};
			
			prefixInput.addEventListener('input', adjustPrefixPosition);
			adjustPrefixPosition(); // Initial adjustment
		}


		//// Function to comma format flagged elements
		function applyCommaFormatting(_obj) {
			var num;
			if (_obj.tagName === "INPUT") {
				num = getNumber(_obj.value);
				if (num == 0) {
					_obj.value = '';
				} else {
					_obj.value = num.toLocaleString('en-GB');
				}
			} else if (_obj.tagName === "SPAN") {
				num = getNumber(_obj.textContent);
				if (num == 0) {
					_obj.textContent = '';
				} else {
					_obj.textContent = num.toLocaleString('en-GB');
				}
			}

			function getNumber(_str) {
				var arr = _str.split('');
				var out = [];
				for (var cnt = 0; cnt < arr.length; cnt++) {
					if (!isNaN(arr[cnt])) {
						out.push(arr[cnt]);
					}
				}
				return Number(out.join(''));
			}
		}

	}
	  
});